import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import BannerModule from "../components/BannerModule/BannerModule"
import Layout from "../components/Layout"
import Seo from "../components/SEO"

import RichText from "../components/RichText"

const DefaultTemplate = contentfulPage => {
  const headerImage = "../../static/banner.png" //getImage(contentfulPage.headerImage)
  return (
    <>
      <Seo title={contentfulPage.title} />
      <Layout>
        <BannerModule
          title={contentfulPage.title}
//          subTitle="Ontstaan uit ambitie, gedreven door passie."
        />
        <div className="section">
          <div className="container container__tight">
            <RichText richText={contentfulPage.mainContent} />
          </div>
        </div>
      </Layout>
    </>
  )
}


export default DefaultTemplate
