import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import ProductFeed from "../components/Feeds/ProductFeed"
import PostFeed from "../components/Feeds/PostFeed"

const getTemplate = (contentfulPage) => {
  const { feedType } = contentfulPage

  switch (feedType) {
    case "Products":
      return <ProductFeed {...contentfulPage} />

    default:
      return <PostFeed {...contentfulPage} />
  }
}

const FeedTemplate = (contentfulPage) => {
  const headerImage = getImage(contentfulPage.headerImage)
  return (
    <>
      <Seo title={contentfulPage.title} />
      <Layout>
        <BannerModule
          title={contentfulPage.title}
//          subTitle="Ontstaan uit ambitie, gedreven door passie."
        />

        <div className="section">
          <div className="feed">{getTemplate(contentfulPage)}</div>
        </div>
      </Layout>
    </>
  )
}

export default FeedTemplate
